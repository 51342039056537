.support-page-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  img {
    width: 10rem;
  }
  p {
    text-align: center;
    line-height: 1.5;
  }
}
