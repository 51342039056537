.home-page-container {
  padding: 1rem;
  color: #ffffff;
  @media only screen and (min-width: 600px) {
    padding: 3rem;
  }
  .banner-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    row-gap: 2rem;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }
    .text-wrapper {
      flex: 1;
      font-family: 'bungee', sans-serif;
      font-weight: 800;
      font-size: 2.5rem;
      font-style: normal;
      line-height: 1.4;
      @media only screen and (min-width: 600px) {
        flex: 0.8;
        font-size: 3rem;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 3.5rem;
      }
      @media only screen and (min-width: 1220px) {
        font-size: 4rem;
      }
      @media only screen and (min-width: 1400px) {
        font-size: 5rem;
      }
      .subtle {
        font-family: 'bungee', sans-serif;
        font-weight: 700;
        font-style: normal;
      }
    }
    .logo-wrapper {
      .app-logo {
        width: 5.5rem;
        @media only screen and (min-width: 600px) {
          width: 5.3rem;
        }
        @media only screen and (min-width: 1024px) {
          width: 5.8rem;
        }
        @media only screen and (min-width: 1220px) {
          width: 6rem;
        }
        @media only screen and (min-width: 1400px) {
          width: 6.5rem;
        }
      }
    }
    .fillers {
      position: absolute;
      width: 8rem;
      @media only screen and (min-width: 600px) {
        width: 9rem;
      }
      @media only screen and (min-width: 1024px) {
        width: 10rem;
      }
      @media only screen and (min-width: 1220px) {
        width: 11rem;
      }
      @media only screen and (min-width: 1400px) {
        width: 12rem;
      }
      &.fill-hummus {
        top: 2rem;
        right: -5rem;
        @media only screen and (min-width: 600px) {
          top: 2rem;
          right: -5rem;
        }
        @media only screen and (min-width: 1024px) {
          top: 2rem;
          right: -5rem;
        }
        @media only screen and (min-width: 1220px) {
          top: 2rem;
          right: -5rem;
        }
        @media only screen and (min-width: 1400px) {
          top: 0rem;
          right: -10rem;
        }
      }
      &.fill-pesto {
        top: 20rem;
        right: 0rem;
        @media only screen and (min-width: 600px) {
          top: 10rem;
          right: 4rem;
        }
        @media only screen and (min-width: 1024px) {
          top: 10rem;
          right: 4rem;
        }
        @media only screen and (min-width: 1220px) {
          top: 20rem;
          right: 0rem;
        }
        @media only screen and (min-width: 1400px) {
          top: 20rem;
          right: -5rem;
        }
      }
      &.fill-pepper {
        top: 40rem;
        right: -3rem;
        @media only screen and (min-width: 600px) {
          top: 20rem;
          right: 0rem;
        }
        @media only screen and (min-width: 1024px) {
          top: 25rem;
          right: 12rem;
        }
        @media only screen and (min-width: 1220px) {
          top: 18rem;
          right: 20rem;
        }
        @media only screen and (min-width: 1400px) {
          top: 27rem;
          right: 20rem;
        }
      }
    }
  }
  .partner-box {
    margin-top: 3rem;
    p {
      font-size: 0.7rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
    ul {
      margin-top: 1rem;
      .partner-logo {
        height: 2rem;
      }
    }
  }
  .feedback-box {
    position: relative;
    margin-top: 5rem;
    width: 100%;
    @media only screen and (min-width: 600px) {
      width: 80%;
    }
    @media only screen and (min-width: 1024px) {
      width: 70%;
    }
    @media only screen and (min-width: 1220px) {
      width: 70%;
    }
    @media only screen and (min-width: 1400px) {
      width: 60%;
    }
    p {
      font-size: 1rem;
      line-height: 2;
      margin-bottom: 0.5rem;
      @media only screen and (min-width: 600px) {
        font-size: 0.9rem;
        line-height: 1.7;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 1rem;
      }
    }
    .drama-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      padding-bottom: 0.3rem;
      @media only screen and (min-width: 600px) {
        flex-direction: row;
        align-items: center;
        column-gap: 1rem;
      }
      h1 {
        font-family: 'lust-script', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 3rem;
        @media only screen and (min-width: 600px) {
          font-size: 3rem;
        }
        @media only screen and (min-width: 1024px) {
          font-size: 3.5rem;
        }
        @media only screen and (min-width: 1220px) {
          font-size: 4rem;
        }
        @media only screen and (min-width: 1400px) {
          font-size: 4rem;
        }
      }
    }
    .feedback-link {
      display: block;
      text-align: center;
      margin-top: 3rem;
      padding: 1rem 2rem;
      font-size: 0.9rem;
      text-transform: uppercase;
      background: #ffffff;
      border: 2px solid #ffffff;
      border-radius: 65px;
      text-decoration: none;
      color: #000000;
      font-family: 'bw_modelicabold';
      &:hover {
        background: transparent;
        color: #ffffff;
      }
      @media only screen and (min-width: 600px) {
        padding: 0.7rem 1.5rem;
        font-size: 0.8rem;
        width: max-content;
      }
      @media only screen and (min-width: 1024px) {
        padding: 1rem 2rem;
        font-size: 0.9rem;
      }
    }
    .bread-loaf {
      position: absolute;
      width: 30rem;
      top: -10rem;
      right: -19rem;
      rotate: -30deg;
      user-select: none;
      @media only screen and (min-width: 600px) {
        width: 35rem;
        top: -8rem;
        right: -30rem;
        rotate: -40deg;
      }
      @media only screen and (min-width: 1024px) {
        width: 45rem;
        top: 0rem;
        right: -40rem;
        rotate: -30deg;
      }
      @media only screen and (min-width: 1220px) {
        width: 52rem;
        top: 0rem;
        right: -40rem;
        rotate: -30deg;
      }
      @media only screen and (min-width: 1400px) {
        width: 55rem;
        top: -5rem;
        right: -45rem;
        rotate: -30deg;
      }
    }
  }
  .typo-box {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
    @media only screen and (min-width: 600px) {
      justify-content: flex-start;
    }
    .boologam-item {
      height: 3rem;
    }
    .kattravai-item {
      height: 2.7rem;
    }
  }
}
