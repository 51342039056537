@font-face {
  font-family: 'bw_modelicathin';
  src: url('~@fonts/BwModelica-Thin.woff2') format('woff2'), url('~@fonts/BwModelica-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bw_modelicabold';
  src: url('~@fonts/bwmodelica-bold-webfont.woff2') format('woff2'),
    url('~@fonts/bwmodelica-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ease-element {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

html {
  font-size: 16px;
  font-family: 'bw_modelicathin';
  background-color: #2d3741;
  display: flex;
  justify-content: center;
  body {
    max-width: 1400px;
    overflow-x: hidden;
  }
  * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
}
